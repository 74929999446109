import React, { createContext, useContext, useReducer } from 'react';
import dayjs from 'dayjs';

export const initialState = {
    //config,
    start: false,
    hasLoaded: false,
    markers: null,
    focusedMarker: null,
    dates: null,
    focusedDate: dayjs().format( 'MM.DD.YY' ),
    data: [],
    isMenuOpen: false
};

export function reducer ( state, action ) {
    const { payload, type } = action;
    switch ( type ) {
        case 'LOADED':
            return {
                ...state,
                hasLoaded: true,
            };
        case 'START':
            return {
                ...state,
                start: true,
            };
        case 'FOCUS':
            return {
                ...state,
                focusedMarker: payload,
            };
        case 'SET_DATE':
            return {
                ...state,
                focusedDate: payload,
            };
        case 'SET_DATES':
            return {
                ...state,
                dates: payload,
            };
        case 'SET_DATA':
            return {
                ...state,
                data: payload,
            };
        case 'SET_MARKERS':
            return {
                ...state,
                markers: payload,
            };
        case 'TOGGLE_MENU':
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen,
            };
        default:
            return state;
    }
}

const LoveContext = createContext( null );

export function LoveProvider ( { children, initialState, reducer } ) {
    return (
        <LoveContext.Provider value={useReducer( reducer, initialState )}>
            {children}
        </LoveContext.Provider>
    );
}

export function useStateValue () {
    return useContext( LoveContext );
}