import React from 'react';

const Icon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259969 4.80026 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19974 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0V0ZM5.475 2.5125C5.475 2.37326 5.53031 2.23973 5.62877 2.14127C5.72723 2.04281 5.86076 1.9875 6 1.9875C6.13924 1.9875 6.27278 2.04281 6.37123 2.14127C6.46969 2.23973 6.525 2.37326 6.525 2.5125V7.0125C6.525 7.15174 6.46969 7.28527 6.37123 7.38373C6.27278 7.48219 6.13924 7.5375 6 7.5375C5.86076 7.5375 5.72723 7.48219 5.62877 7.38373C5.53031 7.28527 5.475 7.15174 5.475 7.0125V2.5125ZM6 9.9375C5.8665 9.9375 5.73599 9.89791 5.62499 9.82374C5.51399 9.74957 5.42747 9.64415 5.37638 9.52081C5.32529 9.39747 5.31193 9.26175 5.33797 9.13081C5.36402 8.99987 5.4283 8.8796 5.5227 8.7852C5.6171 8.6908 5.73738 8.62651 5.86832 8.60047C5.99925 8.57442 6.13497 8.58779 6.25831 8.63888C6.38165 8.68997 6.48707 8.77649 6.56124 8.88749C6.63541 8.99849 6.675 9.129 6.675 9.2625C6.675 9.44152 6.60389 9.61321 6.4773 9.73979C6.35071 9.86638 6.17902 9.9375 6 9.9375Z" fill="#3A3A3A" />
        </svg>
    );
};

export default Icon;
