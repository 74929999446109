import styled from 'styled-components';

export const Wrapper = styled.pre`
    background: rgba(243, 101, 101, 0.05);
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 5px;
	padding: 24px 20px;
	box-sizing: border-box;
	color: black;
	overflow-x: auto;
`

export const Button = styled.div`
    width: 100%;
	display: block;
	background: #f36565;
	border-radius: 8px;
	outline: none;
	border: none;
	padding: 22px 32px;
	text-decoration: none;
	text-align: center;
	box-sizing: border-box;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.1s ease-out;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
`;