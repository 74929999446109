import React, { useEffect } from 'react';
import dayjs from 'dayjs';

import Intro from '../Intro';
import Globe from '../Globe';
import Overlay from '../Overlay';

import ThemeContextProvider from '../../contexts/ThemeContext';
import { useStateValue } from '../../contexts/LoveContext';

import loveService from '../../services/LoveService';
import dateRanger from '../../helpers/range';

const App = () => {
	const [{ markers }, dispatch] = useStateValue();

	const loadData = async () => {
		await setDates();
		await getDataByDate( dayjs().format( 'MM-DD-YYYY' ) );
		dispatch( { type: 'LOADED' } );
	}
	useEffect( () => {
		loadData();
	}, [dispatch] )
	const getDataByDate = async ( date ) => {
		date = dayjs( date ).format( 'MM-DD-YYYY' );

		const loveByDate = await loveService.getByDate( date );
		const markers = await createMarkers( loveByDate );

		dispatch( { type: 'SET_DATA', payload: loveByDate } );
		dispatch( { type: 'SET_MARKERS', payload: markers } );
	};
	const setDates = async () => {
		let dates = await dateRanger( '02-14-2021', dayjs().format( 'MM-DD-YYYY' ) );
		dates = await dates.reverse();
		dispatch( { type: 'SET_DATES', payload: dates } );
	};
	/*getByDomain = async ( domain ) => {
		markers.filter( i => i.domain === domain );
		dispatch( { type: 'SET_DATE', payload: date } );
	}*/
	const setDate = async ( date ) => {
		dispatch( { type: 'SET_DATE', payload: date } );
		await getDataByDate( date );
	};
	const createMarkers = async ( data ) => {
		const markers = [];
		data.forEach( ( item ) => {
			markers.push( {
				id: item.id,
				domain: item.domain,
				city: item.geolocation.city,
				coordinates: [item.geolocation.coordinates.lat, item.geolocation.coordinates.lng],
				value: 50,
			} );
		} );
		return markers;
	};
	return (
		<ThemeContextProvider>
			<Globe />
			<Intro />
			<Overlay setDate={setDate} />
		</ThemeContextProvider>
	);
}

export default App;
