import CountUp from 'react-countup';
import { Wrapper, DateWrapper, TotalNumber } from './styled';

const Number = ( props ) => {
	return (
		<Wrapper>
			<TotalNumber>
				<CountUp end={props.number} />
			</TotalNumber>
			<DateWrapper>
				<span>X</span> LOVE LOADED ON {props.date}
			</DateWrapper>
		</Wrapper>
	);
};
export default Number;
