import dayjs from 'dayjs';
const isSameOrBefore = require( 'dayjs/plugin/isSameOrBefore' );
dayjs.extend( isSameOrBefore );

export default async ( startDate, endDate ) => {
	const start = dayjs( startDate );
	const end = dayjs( endDate );
	let now = dayjs( start );
	let dates = [];

	for ( let i = 0; now.isSameOrBefore( end ); i++ ) {
		dates.push( now.format( 'MM-DD-YYYY' ) );
		now = now.add( 1, 'day' );
	}

	return dates;
};
