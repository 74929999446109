import React from 'react';

import './style.css'
import Fade from '../Fade';
import { Caption, Title, Desc, Button } from './styled';

import { useStateValue } from '../../contexts/LoveContext';

export default function Intro () {
    const [{ hasLoaded, start }, dispatch] = useStateValue();

    return (
        <Fade className="intro fixed" show={!start}>
            <Caption>SPREAD THE LOVE</Caption>
            <Title>The Love Pixel</Title>
            <Desc>A visualization of the love that Donate Attention is spreading around the world.</Desc>
            <Fade show={hasLoaded}>
                <Button onClick={() => dispatch( { type: 'START' } )}>Explore</Button>
            </Fade>
        </Fade>
    );
}