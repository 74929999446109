import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './style.css';
import CodeBlock from './Code';

import { Caption, Titles, Title, Desc, Divider, Backlink, Backlinks, FormWrapper, FormButton, MediaWrapper, MediaTitle, Medialinks, Medialink } from './styled';
import Input from './Input';

import loversService from '../../../../services/LoversService';

const SignupSchema = Yup.object().shape( {
	name: Yup.string().min( 2, 'Too Short!' ).max( 50, 'Your name is too long.' ).required( 'Your Name is required.' ),
	email: Yup.string().email( 'Your email is invalid.' ).required( 'Your Email is required.' ),
	domain: Yup.string()
		.matches( /^[a-z0-9.-]+(\.[a-z]{2,}){1,3}$/, 'Domain Format is invalid.' )
		.required( 'Your Domain is requied' ),
} );

const SignupForm = ( { submit, setSubmit } ) => {
	const formik = useFormik( {
		initialValues: {
			name: '',
			email: '',
			domain: '',
		},
		validationSchema: SignupSchema,
		onSubmit: async ( values ) => {
			//alert(JSON.stringify(values, null, 2));
			const data = {
				name: values.name,
				email: values.email,
				domain: values.domain,
			};
			setSubmit( true );
			/*const isValid = await loversService.validate( data.domain );
			if ( !isValid ) {
				await loversService.add( data );
				setSubmit( true );
			} else {
				console.log( 'Domain already exists!' );
				setSubmit( true );
			}*/
			formik.resetForm();
		},
	} );
	return (
		<div className='form-wrapper'>
			{!submit ? (
				<div>
					<Titles>
						<Caption>SPREAD THE LOVE</Caption>
						<Title>The Love Pixel</Title>
						<Desc>The urge to miniaturize electronics did not exist before the space program. I mean our grandparents had radios that was furniture in the living room. </Desc>
					</Titles>
					<FormWrapper>
						<form onSubmit={formik.handleSubmit}>
							<Input formik={formik} name='name' label='Name' type='text' />
							<Input formik={formik} name='email' label='Email Address' type='text' />
							<Input formik={formik} name='domain' label='Domain' type='text' />
							<FormButton type='submit'>ADD YOUR SITE</FormButton>
						</form>
					</FormWrapper>
					<Divider />
					<div>
						<Caption>MAKE YOUR IMPACT</Caption>
						<Backlinks>
							<Backlink target="_blank" href="https://donateattention.org">
								<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.6985 0L9.82523 0.864355L13.7 4.88191H0V6.11809H13.7L9.82523 10.1356L10.6985 11L16 5.5L10.6985 0Z" fill="#F36565" />
								</svg>
								<span>DONATE ATTENTION</span>
							</Backlink>
							<Backlink target="_blank" href="https://donateattention.org/campaigns/spread-the-love">
								<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.6985 0L9.82523 0.864355L13.7 4.88191H0V6.11809H13.7L9.82523 10.1356L10.6985 11L16 5.5L10.6985 0Z" fill="#F36565" />
								</svg>
								<span>SPREAD THE LOVE</span>
							</Backlink>
							<Backlink target="_blank" href="https://donateattention.org/love">
								<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.6985 0L9.82523 0.864355L13.7 4.88191H0V6.11809H13.7L9.82523 10.1356L10.6985 11L16 5.5L10.6985 0Z" fill="#F36565" />
								</svg>
								<span>THE LOVE PIXEL</span>
							</Backlink>
						</Backlinks>
					</div>
					<Divider />
					<MediaWrapper>
						<MediaTitle>FOLLOW US</MediaTitle>
						<Medialinks>
							<Medialink href="https://facebook.com/donateattention" target="_blank">
								<svg height="14" viewBox="0 0 7 13" fill="#C5C5C5" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.03906 7.25L6.36719 5.09375H4.28125V3.6875C4.28125 3.07812 4.5625 2.51562 5.5 2.51562H6.46094V0.664062C6.46094 0.664062 5.59375 0.5 4.77344 0.5C3.0625 0.5 1.9375 1.55469 1.9375 3.42969V5.09375H0.015625V7.25H1.9375V12.5H4.28125V7.25H6.03906Z" />
								</svg>
							</Medialink>
							<Medialink href="https://twitter.com/donateattention" target="_blank">
								<svg height="14" viewBox="0 0 11 11" fill="#C5C5C5" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.75 2.80469C4.25 2.80469 3.05469 4.02344 3.05469 5.5C3.05469 7 4.25 8.19531 5.75 8.19531C7.22656 8.19531 8.44531 7 8.44531 5.5C8.44531 4.02344 7.22656 2.80469 5.75 2.80469ZM5.75 7.25781C4.78906 7.25781 3.99219 6.48438 3.99219 5.5C3.99219 4.53906 4.76562 3.76562 5.75 3.76562C6.71094 3.76562 7.48438 4.53906 7.48438 5.5C7.48438 6.48438 6.71094 7.25781 5.75 7.25781ZM9.17188 2.71094C9.17188 2.35938 8.89062 2.07812 8.53906 2.07812C8.1875 2.07812 7.90625 2.35938 7.90625 2.71094C7.90625 3.0625 8.1875 3.34375 8.53906 3.34375C8.89062 3.34375 9.17188 3.0625 9.17188 2.71094ZM10.9531 3.34375C10.9062 2.5 10.7188 1.75 10.1094 1.14062C9.5 0.53125 8.75 0.34375 7.90625 0.296875C7.03906 0.25 4.4375 0.25 3.57031 0.296875C2.72656 0.34375 2 0.53125 1.36719 1.14062C0.757812 1.75 0.570312 2.5 0.523438 3.34375C0.476562 4.21094 0.476562 6.8125 0.523438 7.67969C0.570312 8.52344 0.757812 9.25 1.36719 9.88281C2 10.4922 2.72656 10.6797 3.57031 10.7266C4.4375 10.7734 7.03906 10.7734 7.90625 10.7266C8.75 10.6797 9.5 10.4922 10.1094 9.88281C10.7188 9.25 10.9062 8.52344 10.9531 7.67969C11 6.8125 11 4.21094 10.9531 3.34375ZM9.82812 8.59375C9.66406 9.0625 9.28906 9.41406 8.84375 9.60156C8.14062 9.88281 6.5 9.8125 5.75 9.8125C4.97656 9.8125 3.33594 9.88281 2.65625 9.60156C2.1875 9.41406 1.83594 9.0625 1.64844 8.59375C1.36719 7.91406 1.4375 6.27344 1.4375 5.5C1.4375 4.75 1.36719 3.10938 1.64844 2.40625C1.83594 1.96094 2.1875 1.60938 2.65625 1.42188C3.33594 1.14062 4.97656 1.21094 5.75 1.21094C6.5 1.21094 8.14062 1.14062 8.84375 1.42188C9.28906 1.58594 9.64062 1.96094 9.82812 2.40625C10.1094 3.10938 10.0391 4.75 10.0391 5.5C10.0391 6.27344 10.1094 7.91406 9.82812 8.59375Z" />
								</svg>
							</Medialink>
							<Medialink href="https://instagram.com/donateattention" target="_blank">
								<svg height="14" viewBox="0 0 12 11" fill="#C5C5C5" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.7578 3.0625C11.2266 2.71094 11.6484 2.28906 11.9766 1.79688C11.5547 1.98438 11.0625 2.125 10.5703 2.17188C11.0859 1.86719 11.4609 1.39844 11.6484 0.8125C11.1797 1.09375 10.6406 1.30469 10.1016 1.42188C9.63281 0.929688 9 0.648438 8.29688 0.648438C6.9375 0.648438 5.83594 1.75 5.83594 3.10938C5.83594 3.29688 5.85938 3.48438 5.90625 3.67188C3.86719 3.55469 2.03906 2.57031 0.820312 1.09375C0.609375 1.44531 0.492188 1.86719 0.492188 2.33594C0.492188 3.17969 0.914062 3.92969 1.59375 4.375C1.19531 4.35156 0.796875 4.25781 0.46875 4.07031V4.09375C0.46875 5.28906 1.3125 6.27344 2.4375 6.50781C2.25 6.55469 2.01562 6.60156 1.80469 6.60156C1.64062 6.60156 1.5 6.57812 1.33594 6.55469C1.64062 7.53906 2.55469 8.24219 3.63281 8.26562C2.78906 8.92188 1.73438 9.32031 0.585938 9.32031C0.375 9.32031 0.1875 9.29688 0 9.27344C1.07812 9.97656 2.36719 10.375 3.77344 10.375C8.29688 10.375 10.7578 6.64844 10.7578 3.39062C10.7578 3.27344 10.7578 3.17969 10.7578 3.0625Z" />
								</svg>
							</Medialink>
							<Medialink href="https://linkedin.com/donateattention" target="_blank">
								<svg height="14" viewBox="0 0 11 11" fill="#C5C5C5" xmlns="http://www.w3.org/2000/svg">
									<path d="M2.84375 11V3.99219H0.664062V11H2.84375ZM1.74219 3.05469C2.44531 3.05469 3.00781 2.46875 3.00781 1.76562C3.00781 1.08594 2.44531 0.523438 1.74219 0.523438C1.0625 0.523438 0.5 1.08594 0.5 1.76562C0.5 2.46875 1.0625 3.05469 1.74219 3.05469ZM10.9766 11H11V7.15625C11 5.28125 10.5781 3.82812 8.375 3.82812C7.32031 3.82812 6.61719 4.41406 6.3125 4.95312H6.28906V3.99219H4.20312V11H6.38281V7.53125C6.38281 6.61719 6.54688 5.75 7.67188 5.75C8.79688 5.75 8.82031 6.78125 8.82031 7.60156V11H10.9766Z" />
								</svg>
							</Medialink>
						</Medialinks>
					</MediaWrapper>
				</div>
			) : (
					<div>
						<Titles>
							<Caption>THANK YOU</Caption>
							<Title>Check Your Inbox</Title>
							<Desc>The urge to miniaturize electronics did not exist before the space program. I mean our grandparents had radios that was furniture in the living room. </Desc>
						</Titles>
						<Divider />
						<div>
							<Caption>HOW TO INSTALL?</Caption>
							<Desc>Copy and past the code into every page of your site, just before the {'</body>'} tag.</Desc>
							<CodeBlock />
						</div>
					</div>
				)}
		</div>
	);
};

export default SignupForm;
