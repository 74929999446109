import styled from 'styled-components';

export const StyledMenu = styled.nav`
	padding: 32px;
	box-sizing: border-box;
	width: 440px;
	background: #fff;
	height: 100vh;
	text-align: left;
	position: absolute;
	top: 0;
	left: 0;
	transition: transform 0.3s ease-in-out;
	transform: ${( { open } ) => ( open ? 'translateX(0)' : 'translateX(-100%)' )};
	z-index: 9;
	box-shadow: inset -10px 0px 21px rgba(0, 0, 0, 0.15);
	@media (max-width: ${( { theme } ) => theme.mobile}) {
		width: 100%;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 56px;
`;

export const BackIcon = styled.svg`
	cursor: pointer;
	&:hover {
        fill: #222222;
    }
`;
