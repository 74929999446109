import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu, Header, BackIcon } from './styled';
import Form from './Form';

const Menu = ( { open, setOpen, submit, setSubmit } ) => {
	return (
		<StyledMenu open={open}>
			<Header>
				<svg width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<ellipse cx="10.9644" cy="10.4404" rx="9.396" ry="8.352" fill="white" />
					<path fillRule="evenodd" clipRule="evenodd" d="M11.1441 6.54984C11.0786 6.77161 11.0432 7.00744 11.0432 7.25065C11.0432 8.58259 12.0988 9.66262 13.4005 9.66262C13.6388 9.66262 13.8687 9.62644 14.0854 9.55944C14.1509 9.85089 14.1862 10.1551 14.1862 10.4666C14.1862 12.687 12.4275 14.4866 10.2575 14.4866C8.08751 14.4866 6.32874 12.687 6.32874 10.4666C6.32874 8.24626 8.08751 6.44666 10.2575 6.44666C10.562 6.44666 10.8586 6.48284 11.1441 6.54984Z" fill="#F36565" />
					<path fillRule="evenodd" clipRule="evenodd" d="M11.1662 26.2034C11.0025 26.056 10.842 25.9019 10.6862 25.7418L3.00418 17.8814C1.66906 16.5153 0.779202 14.8705 0.333944 13.1318C-0.111315 11.3939 -0.111315 9.56212 0.333944 7.82416C0.779202 6.08621 1.66906 4.44071 3.00484 3.07459C4.33996 1.70781 5.94747 0.79729 7.64666 0.341696C9.34519 -0.113899 11.1354 -0.113899 12.8339 0.341696C14.5324 0.79729 16.1406 1.70781 17.4757 3.07459L18.27 3.88729L19.0643 3.07459C20.3994 1.70781 22.0076 0.79729 23.7061 0.341696C25.4046 -0.113899 27.1948 -0.113899 28.8933 0.341696C30.5919 0.79729 32.2 1.70781 33.5352 3.07459C34.8703 4.44071 35.7608 6.08621 36.2061 7.82416C36.6513 9.56212 36.6513 11.3939 36.2061 13.1318C35.7608 14.8698 34.8709 16.5153 33.5352 17.8814L25.8545 25.7418C25.5899 26.0124 25.3142 26.2657 25.0294 26.5009L21.2067 30.4123C19.5704 32.0866 16.9165 32.0866 15.2802 30.4123L11.1662 26.2034ZM10.2553 18.7752C14.7394 18.7752 18.3748 15.0554 18.3748 10.4673C18.3748 5.87918 14.7394 2.15938 10.2553 2.15938C5.77133 2.15938 2.13593 5.87918 2.13593 10.4673C2.13593 15.0554 5.77133 18.7752 10.2553 18.7752Z" fill="#F36565" />
				</svg>
				<BackIcon onClick={setOpen} width="25" height="25" viewBox="0 0 25 25" fill="#DFDFDF" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.5 0C5.59625 0 0 5.59625 0 12.5C0 19.4037 5.59625 25 12.5 25C19.4037 25 25 19.4037 25 12.5C25 5.59625 19.4037 0 12.5 0ZM18.75 13.75H10.5175L13.3837 16.6163L11.6163 18.3838L5.7325 12.5L11.6163 6.61625L13.3837 8.38375L10.5175 11.25H18.75V13.75Z" />
				</BackIcon>
			</Header>
			<Form setSubmit={setSubmit} submit={submit} />
		</StyledMenu>
	);
};

Menu.propTypes = {
	open: bool.isRequired,
};
export default Menu;
