export const theme = {
	primaryDark: '#0D0C1D',
	primaryLight: '#EFFFFA',
	primaryHover: '#343078',
	mobile: '576px',
	colors: {
		primary: {
			400: '#F36565',
		},
	},
};
