import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 16px 64px;
	width: 100%;
	box-sizing: border-box;
`;

export const Code = styled.div`
    font-weight: 600;
	font-size: 12px;
	line-height: 148.9%;
	text-align: right;
	color: #3d3d3d;
	opacity: 0.5;
`;
