import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './global';
import { theme } from './theme';

class ThemeContextProvider extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<ThemeProvider theme={theme}>
				<>
					<GlobalStyles />
					<>{this.props.children}</>
				</>
			</ThemeProvider>
		);
	}
}

export default ThemeContextProvider;
