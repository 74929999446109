import axios from 'axios';
import { Storage } from 'aws-amplify';

Storage.configure( {
	customPrefix: {
		public: '',
		protected: 'myProtectedPrefix/',
		private: 'myPrivatePrefix/',
	},
} );

class LoveService {
	getByDate = async ( date ) => {
		let data = [];
		try {
			const list = await Storage.list( date + '/' );
			//console.log(list);
			for ( let i = 0; i < list.length; i++ ) {
				const key = list[i].key;
				try {
					const url = await Storage.get( key, { cacheControl: 'no-cache' } );
					//console.log(url);
					try {
						const res = await axios.get( url );
						//console.log(res.data);
						data.push( res.data );
					} catch ( e ) {
						//console.log(e);
					}
				} catch ( e ) {
					//console.log(e);
				}
			}
		} catch ( e ) {
			//console.log(e);
		}

		//console.log(data);
		return data;
	};
	get = async () => {
		let data = [];
		try {
			const list = await Storage.list( '' );
			//console.log(list);
			for ( let i = 0; i < list.length; i++ ) {
				const key = list[i].key;
				try {
					const url = await Storage.get( key, { cacheControl: 'no-cache' } );
					//console.log(url);
					try {
						const res = await axios.get( url );
						//console.log(res.data);
						data.push( res.data );
					} catch ( e ) {
						//console.log(e);
					}
				} catch ( e ) {
					//console.log(e);
				}
			}
		} catch ( e ) {
			//console.log(e);
		}

		//console.log(data);
		return data;
	};
}

export default new LoveService();
