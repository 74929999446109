import React from 'react';

import { Wrapper, Content, Divider } from './styled';
import { useStateValue } from '../../contexts/LoveContext';
import Searchbar from '../Searchbar';
import SideNav from '../SideNav';
import Logo from '../Logo';


const Header = () => {
	const [{ isMenuOpen }, dispatch] = useStateValue();
	return (
		<Wrapper>
			<Content>
				<SideNav isOpen={isMenuOpen} setOpen={() => dispatch( { type: "TOGGLE_MENU" } )} />
				<Divider />
				<Logo />
			</Content>
			<Searchbar />
		</Wrapper>
	);
};

export default Header;
