import styled from 'styled-components';

export const Wrapper = styled.div`
    border-bottom: 2px solid #454545;
	width: 100%;
	padding: 0;
	background-color: transparent;
	position: absolute;
	top: 0px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	height: 64px;
`;

export const Content = styled.div`
    height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const Divider = styled.div`
    width: 2px;
	height: 64px;
	background-color: #454545;
`;