import React from 'react';

const Icon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0C2.6865 0 0 2.6865 0 6C0 9.3135 2.6865 12 6 12C9.3135 12 12 9.3135 12 6C12 2.6865 9.3135 0 6 0ZM9.3535 4.8535L5.5735 8.6335C5.4795 8.7275 5.3525 8.78 5.22 8.78C5.0875 8.78 4.96 8.7275 4.8665 8.6335L3.14 6.907C2.9445 6.7115 2.9445 6.3955 3.14 6.2C3.3355 6.0045 3.6515 6.0045 3.847 6.2L5.22 7.573L8.6465 4.1465C8.842 3.951 9.158 3.951 9.3535 4.1465C9.549 4.342 9.549 4.658 9.3535 4.8535Z" fill="#8AD19E" fillOpacity="0.7" />
        </svg>
    );
};

export default Icon;
