import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0 0 16px;
`

export const Field = styled.div`
    border: none;
	position: relative;
	margin: 0 0 4px;
	padding: 0;
    svg {
        position: absolute;
        top: calc(calc(56px - 20px) / 2);
        right: 24px;
        z-index: 99;
    }
`
export const Label = styled.label`
    position: absolute;
	top: calc(calc(56px - 20px) / 2);
	left: 24px;
	color: #626262;
	font-weight: 400;
	transition: all 0.2s ease-out;
    font-size: ${( { isFloat } ) => !isFloat ? '16px' : '9px'};
    line-height: 100%;
    transform: ${( { isFloat } ) => !isFloat ? 'translateY(0)' : 'translateY(-22px)'};
    background-color: white;
    padding: ${( { isFloat } ) => !isFloat ? '0' : '0 4px'};
`

export const FieldInput = styled.input`
    width: 100%;
	padding: 0px 24px !important;
	height: 56px !important;
	box-shadow: none !important;
	background: #fbfbfb;
	border: 1px solid #b1b1b1;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 14px;
	line-height: 100%;
	color: #626262;
	box-shadow: none !important;
	outline: none !important;
    &:focus {
        border: 2px solid #b1b1b1;
    }
`

export const Error = styled.div`
    font-size: 10px;
	color: #222222;
`