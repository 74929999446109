import LoveCounter from './LoveCounter';
import DateSlider from './DateSlider';
import { Wrapper } from './styled';

import Footer from '../Footer';

const Menu = ( props ) => {
	const love = props.totalLove === 0 ? 0 : props.totalLove;
	return (
		<Wrapper>
			<LoveCounter number={love} date={props.date} />
			<DateSlider dates={props.dates} date={props.date} setDate={props.setDate} />
			<Footer />
		</Wrapper>
	);
};

export default Menu;
