import React, { useState } from 'react';

import { Wrapper, Button } from './styled';

const Code = () => {
	const [response, setResponse] = useState( 'COPY CODE' );
	const copyToClipBoard = async () => {
		try {
			const text = `<script src=“https://sdks.donateattention.org/love-pixel/1.0.0/love.js></script>`
			await navigator.clipboard.writeText( text );
			createResponse( 'Copied' )

		} catch ( err ) {
			createResponse( 'TRY AGAIN' )
		}
	};
	const createResponse = ( response ) => {
		setResponse( response )
		setTimeout( () => {
			setResponse( 'COPY CODE' )
		}, 1500 );
	}
	return (
		<div>
			<Wrapper onClick={copyToClipBoard}>
				<code>
					{`<script src=“https://sdks.donateattention.org/love-pixel/1.0.0/love.js></script>`}
				</code>
			</Wrapper>
			<Button onClick={copyToClipBoard}>{response}</Button>
		</div>
	);
};
export default Code;
