import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import { LoveProvider, initialState, reducer } from './contexts/LoveContext';

import awsExports from './aws-exports';
Amplify.configure( awsExports );


function SRC () {
    return (
        <LoveProvider initialState={initialState} reducer={reducer}><App /></LoveProvider>
    )
}

ReactDOM.render( <SRC />, document.getElementById( 'root' ) );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
