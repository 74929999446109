import { BACKGROUND_RADIUS_SCALE, MarkerTypes } from './enums';

export const defaultCallbacks = {
	onClickMarker: (_marker, _markerObject, _event) => {},
	onDefocus: (_previousFocus) => {},
	onGlobeBackgroundTextureLoaded: () => {},
	onGlobeCloudsTextureLoaded: () => {},
	onGlobeTextureLoaded: () => {},
	onMouseOutMarker: (_marker, _markerObject, _event) => {},
	onMouseOverMarker: (_marker, _markerObject, _event) => {},
};

export { default as defaultGlobeBackgroundTexture } from './textures/background-5.jpg';
export { default as defaultGlobeCloudsTexture } from './textures/clouds-2.jpg';
export { default as defaultGlobeTexture } from './textures/earth-2.jpg';

export const defaultInitialCoordinates = [0, 0]; //[34.052235, -118.243683]; // LA, CA, USA!

export const defaultOptions = {
	ambientLightColor: 'white',
	ambientLightIntensity: 0.8,
	cameraAutoRotateSpeed: 0.1,
	cameraDistanceRadiusScale: 3,
	cameraMaxDistanceRadiusScale: BACKGROUND_RADIUS_SCALE,
	cameraMaxPolarAngle: 60,
	cameraMinPolarAngle: -60,
	cameraRotateSpeed: 0.2,
	cameraZoomSpeed: 0,
	enableCameraAutoRotate: true,
	enableCameraRotate: true,
	enableCameraZoom: true,
	enableDefocus: true,
	enableGlobeGlow: true,
	enableMarkerGlow: true,
	enableMarkerTooltip: true,
	focusAnimationDuration: 1000,
	focusDistanceRadiusScale: 1.5,
	focusEasingFunction: ['Cubic', 'Out'],
	globeCloudsOpacity: 0.3,
	globeGlowCoefficient: 0.05,
	globeGlowColor: '#d1d1d1',
	globeGlowPower: 2,
	globeGlowRadiusScale: 0.1,
	markerEnterAnimationDuration: 1000,
	markerEnterEasingFunction: ['Linear', 'None'],
	markerExitAnimationDuration: 500,
	markerExitEasingFunction: ['Cubic', 'Out'],
	markerGlowCoefficient: 5,
	markerGlowPower: 5,
	markerGlowRadiusScale: 2,
	markerOffsetRadiusScale: 0,
	markerRadiusScaleRange: [0.005, 0.02],
	markerRenderer: null,
	markerTooltipRenderer: (marker) => JSON.stringify(marker.city),
	markerType: MarkerTypes.DOT,
	pointLightColor: 'white',
	pointLightIntensity: 1,
	pointLightPositionRadiusScales: [-2, 1, -1],
};

export const defaultDotMarkerOptions = {
	enableMarkerGlow: true,
	markerRadiusScaleRange: [0.005, 0.02],
	markerType: 'dot',
};

export const defaultBarMarkerOptions = {
	enableMarkerGlow: false,
	markerRadiusScaleRange: [0.2, 0.5],
	markerType: 'bar',
};
