import styled from 'styled-components';
export const StyledBurger = styled.button`
	position: absolute;
	top: 20px;
	left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 24px;
	height: 24px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 8;

	&:focus {
		outline: none;
	}

	div {
		width: 24px;
		height: 2px;
		background: ${( { theme, open } ) => ( open ? theme.primaryDark : theme.primaryLight )};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;
		:first-child {
			transform: ${( { open } ) => ( open ? 'rotate(45deg)' : 'rotate(0)' )};
		}

		:nth-child(2) {
			opacity: ${( { open } ) => ( open ? '0' : '1' )};
			transform: ${( { open } ) => ( open ? 'translateX(10px)' : 'translateX(0)' )};
		}

		:nth-child(3) {
			transform: ${( { open } ) => ( open ? 'rotate(-45deg)' : 'rotate(0)' )};
		}
	}
`;
