import styled from 'styled-components';

export const Titles = styled.div`
    margin: 0 0 24px;
`

export const Caption = styled.h3`
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #222222;
`
export const Title = styled.h2`
    font-family: Merriweather;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 136%;
    letter-spacing: -0.015em;
    color: #222222;
`
export const Desc = styled.p`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 165%;
    color: #626262;
`

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #EBEBEB;
    margin: 36px 0;
`;

export const Backlinks = styled.div`
    margin: 20px 0 0;
`;

export const Backlink = styled.a`
    display: flex;
    align-items: center;
    padding: 8px 0px;
    font-weight: 600;
    font-size: 10px;
    line-height: 165%;
    letter-spacing: 0.1em;
    color: #626262;
    text-decoration: none;
    transition: all .2s ease-out;
    span {
        margin-left: 12px;
    }
    &:hover {
        span {
            color: #F36565;
        }
    }
`;

export const FormWrapper = styled.div`
    margin: 0;
`;

export const MediaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Medialinks = styled.div`
    margin: 0;
    display: flex;
    align-items: center;
`;

export const Medialink = styled.a`
    display: flex;
    align-items: center;
    padding: 0px 16px;
    font-weight: 600;
    font-size: 10px;
    line-height: 165%;
    letter-spacing: 0.1em;
    color: #626262;
    text-decoration: none;
    span {
        margin-left: 12px;
    }
    &:hover {
        svg {
            fill: #F36565;
        }
    }
`;

export const MediaTitle = styled.h4`
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: #222222;
`;

export const FormButton = styled.button`
    width: 100%;
	display: block;
	background: rgba(243, 101, 101, 0.05);
    border: 2px solid #F36565;
    box-sizing: border-box;
    border-radius: 5px;
	outline: none;
	padding: 21px 32px;
	text-decoration: none;
	text-align: center;
	box-sizing: border-box;
	color: #F36565;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.1 ease-out;
	font-size: 12px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
    &:hover {
        color: #fff;
        background-color: #F36565;
    }
`;