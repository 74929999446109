import React, { Component } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import './style.css';

import Slide from './Slide';
import dayjs from 'dayjs';

export default class DateSlider extends React.Component {
	/**
	 * ThumbnailExample constructor.
	 *
	 * @param {Object} props - Props.
	 */
	constructor(props) {
		super(props);

		this.primaryRef = React.createRef();
		this.secondaryRef = React.createRef();
	}

	/**
	 * Set the sync target right after the component is mounted.
	 */
	componentDidMount() {
		this.primaryRef.current.sync(this.secondaryRef.current.splide);
	}

	/**
	 * Render the component.
	 *
	 * @return {ReactNode} - React component.
	 */
	render() {
		const primaryOptions = {
			type: 'loop',
			perPage: 2,
			perMove: 1,
			gap: 0,
			pagination: false,
			arrows: false,
		};

		const secondaryOptions = {
			type: 'slide',
			rewind: true,
			gap: 0,
			pagination: false,
			focus: 0,
			start: 0,
			isNavigation: true,
			updateOnMove: true,
			perPage: 7,
			padding: 0,
			direction: 'rtl',
			arrows: false,
		};

		return (
			<div className='wrapper'>
				<Splide options={primaryOptions} ref={this.primaryRef}>
					{this.props.dates.map((date, index) => (
						<div key={index}></div>
					))}
				</Splide>
				<Splide options={secondaryOptions} ref={this.secondaryRef}>
					{this.props.dates.map((date, index) => (
						<SplideSlide key={index}>
							<Slide setDate={this.props.setDate} active={this.props.date === dayjs(date).format('MM.DD.YY')} date={dayjs(date).format('MM.DD.YY')} />
						</SplideSlide>
					))}
				</Splide>
			</div>
		);
	}
}
