import React, { useState } from 'react';
import { Field, Input, Icon } from './styled';

const Searchbar = () => {
	const [query, setQuery] = useState( '' );
	return (
		<Field>
			<Input placeholder='Search domain or location' onChange={( value ) => setQuery( value )} />
			<Icon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					opacity='0.45'
					d='M7.35 0C3.30314 0 0 3.30314 0 7.35C0 11.3969 3.30314 14.7 7.35 14.7C9.1854 14.7 10.8623 14.0154 12.1529 12.8953L12.6 13.3424V14.7L18.9 21L21 18.9L14.7 12.6H13.3424L12.8953 12.1529C14.0154 10.8623 14.7 9.1854 14.7 7.35C14.7 3.30314 11.3969 0 7.35 0ZM7.35 2.1C10.2619 2.1 12.6 4.43807 12.6 7.35C12.6 10.2619 10.2619 12.6 7.35 12.6C4.43807 12.6 2.1 10.2619 2.1 7.35C2.1 4.43807 4.43807 2.1 7.35 2.1Z'
					fill='#AFAFAF'
				/>
			</Icon>
		</Field>
	);
};
export default Searchbar;
