/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cf59f5af-051f-45f4-9087-0e8292d42367",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MxskAGSYP",
    "aws_user_pools_web_client_id": "7tk25q6rh8snvdaja564vpjhg0",
    "oauth": {},
    "aws_user_files_s3_bucket": "lovepixel-love",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
