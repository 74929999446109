import React from 'react';
import ReactGlobe from './Core';

import markerRenderer from './markerRenderer';
import { useStateValue } from '../../contexts/LoveContext';
import Fade from '../Fade';

const options = {
	markerRenderer,
};

const Globe = ( props ) => {
	const [{ hasLoaded, markers, start }] = useStateValue();
	return (
		<>
			<div className={hasLoaded ? undefined : 'hidden'}>
				<ReactGlobe height='100vh' width='100vw' options={options} markers={start ? markers : []} />
				<Fade animationDuration={3000} className="fixed" show={!hasLoaded} />
			</div>
		</>
	);
}

export default Globe;
