import { Wrapper, Code } from './styled';

const Footer = () => {
	return (
		<Wrapper>
			<Code>· 01101100 01101111 01110110 01100101 ·</Code>
		</Wrapper>
	);
};
export default Footer;
