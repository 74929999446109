import React, { useState } from 'react';

import { Wrapper } from './styled';
import Burger from './Burger';
import Menu from './Menu';

const SideNav = ( props ) => {
	const [submit, setSubmit] = useState( false );
	const onClose = () => {
		props.setOpen();
		setTimeout( () => {
			setSubmit( false );
		}, 100 );
	};
	return (
		<Wrapper>
			<Burger open={props.isOpen} setOpen={onClose} />
			<Menu open={props.isOpen} setOpen={props.setOpen} setSubmit={setSubmit} submit={submit} />
		</Wrapper>
	);
};
export default SideNav;
