import styled from 'styled-components';

export const Caption = styled.h4`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #F36565;
    margin: 0 0 12px;
`;

export const Title = styled.h1`
    font-family: Merriweather;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 136.4%;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 16px;
`;

export const Desc = styled.p`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 165%;
    text-align: center;
    color: #827E7E;
    margin: 0 auto 24px;
    width: 400px;
`;

export const Button = styled.div`
    width: auto;
	display: block;
	background: #F36565;
    border: none;
    box-sizing: border-box;
    border-radius: 24px;
	outline: none;
	padding: 18px 32px;
	text-decoration: none;
	text-align: center;
	box-sizing: border-box;
	color: #FFFFFF;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.1 ease-out;
	font-size: 12px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
    z-index: 999;
    &:hover {
        background-color: #F36565;
    }
`;