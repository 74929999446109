import axios from 'axios';
import { Storage } from 'aws-amplify';

Storage.configure({
	customPrefix: {
		public: '',
		protected: 'myProtectedPrefix/',
		private: 'myPrivatePrefix/',
	},
});

class LoversService {
	add = async (data) => {
		try {
			const key = data.domain + '.json';
			const json = JSON.stringify(data);
			await Storage.put(key, json, {
				bucket: 'lovepixel-lovers',
			});
		} catch (e) {
			console.log(e);
		}
	};
	validate = async (domain) => {
		let check = false;
		const key = domain + '.json';
		try {
			const url = await Storage.get(key, { bucket: 'lovepixel-lovers', cacheControl: 'no-cache' });
			//console.log(url);
			try {
				const res = await axios.get(url);
				console.log(res.data);
				check = true;
			} catch (e) {
				check = false;
				//console.log(e);
			}
		} catch (e) {
			check = false;
			//console.log(e);
		}
		return check;
	};
}

export default new LoversService();
