import styled from 'styled-components';

export const Field = styled.div`
    width: 475px;
	display: flex;
	justify-content: space-between;
	border-left: 2px solid #454545;
	position: relative;
`;

export const Input = styled.input`
    width: 100%;
	height: 64px;
	background-color: transparent;
	outline: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 100%;
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;
	color: rgba(175, 175, 175, 0.51);
	padding: 12px 32px;
	box-sizing: border-box;
	border: none;
`;
export const Icon = styled.svg`
    position: absolute;
	top: calc(50% - 12px);
	right: 32px;
`;