import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
	padding: 18px 64px 32px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	box-sizing: border-box;
	margin: 0 0 24px;
`;

export const DateWrapper = styled.div`
    font-weight: bold;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: 0.1em;
	color: #8b8b8b;
    span {
        color: #f36565;
    }
`;

export const TotalNumber = styled.div`
    font-weight: bold;
	font-size: 48px;
	line-height: 100%;
	letter-spacing: 0.1em;
	opacity: 0.8;
	color: #fff;
	margin: 0 0 12px;
`;