import React, { useState } from 'react';

import { useStateValue } from '../../contexts/LoveContext';
import Fade from '../Fade';
import Header from '../Header';
import DateRanger from '../Menu';

const Overlay = ( props ) => {
    const [{ focusedMarker, markers, start, dates, focusedDate }, dispatch] = useStateValue();

    const showOverlay = start && !focusedMarker;

    return (
        <>
            <Fade className="overlay" show={showOverlay}>
                <Header />
                <DateRanger dates={dates} totalLove={markers !== null ? markers.length : 0} date={focusedDate} setDate={props.setDate} />
            </Fade>
        </>
    );
}

export default Overlay;