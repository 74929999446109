import React, { useState } from 'react';

import { Error, Wrapper, Field, Label, FieldInput } from './styled';
import ErrorIcon from './components/ErrorIcon';
import SuccessIcon from './components/SuccessIcon';

const Input = ( { formik, label, name, type } ) => {
	const [active, setActive] = useState( false );
	const onFocus = () => {
		setActive( true );
	};
	const onBlur = ( e ) => {
		console.log( e.target.value )
		if ( e.target.value === '' ) {
			setActive( false );
		}
	};
	return (
		<Wrapper>
			<Field>
				<Label isFloat={active} htmlFor={name}>{label}</Label>
				<FieldInput onFocus={onFocus} onBlur={onBlur} id={name} name={name} type={type} onChange={formik.handleChange} />
				{formik.errors[name] && formik.touched[name] && (
					<ErrorIcon />
				)}
				{!formik.errors[name] && formik.touched[name] && (
					<SuccessIcon />
				)}
			</Field>
			{formik.errors[name] && formik.touched[name] ? <Error>{formik.errors[name]}</Error> : null}
		</Wrapper>
	);
};

export default Input;
